.Section {
	padding-top: 20px;
  padding-bottom: 5px;
  padding-left: var(--padding-left);
  padding-right: var(--padding-right);
}

.title {
	font-size: 20px;
  font-weight: 600;
}