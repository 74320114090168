@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import 'styles/breakpoints';

:root {
  --color-2: #fafafa;
	--color-3: #c23e01;
  --color-3-light: #d54500;
  --color-4: #525659;
  --color-5: #4caf50;
  --padding-left: 50px;
  --padding-right: 10px;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
}

@media only screen and (max-width: $mobile) {
	:root {
    --padding-left: 20px;
  }
}