@import 'styles/breakpoints';

.Header {
	display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-4);
  color: var(--color-2);
  padding: 15px 0;
  padding-left: var(--padding-left);
  padding-right: var(--padding-right);
}

.info {
	display: inline-flex;
	align-items: center;

	&__titles {
		> h1 {
			font-size: 20px;
  		font-weight: 600;
		}
		> h2 {
			font-size: 11px;
  		font-weight: 600;
		}
	}
}

.btnContact {
	background-color: var(--color-5);
	outline-color: var(--color-5);
	padding: 10px;
	color: var(--color-2);
	font-weight: 600;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	display: flex;
	align-items: center;

	&:hover {
		opacity: 0.9;
	}
	&--mobile {
		display: none;
	}
	&__icon {
		margin-right: 5px;
	}
}

@media only screen and (max-width: $mobile) {
	.Header {
		position: fixed;
		width: 100%;
	}

	.btnContact {
		&--desktop {
			display: none;
		}
		&--mobile {
			display: inherit;
		}
	}
}